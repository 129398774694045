<template>
  <div style="text-align: center;color:white;font-size: 12px;padding: 5px;padding-bottom: 10%">
    <v-card>
      <v-card-title>
        پیام های پشتیبانی
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details class="searchStyle"></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="tickets" :search="search">
        <template v-slot:item.operations="{ item }">
          <v-row>
            <router-link to="replyTicket">
              <v-btn color="success" @click="$root.replyTicket = item.id" style="margin-left: 5px"> جزییات</v-btn>
            </router-link>
            <v-btn v-if="item.status != 'ClosedBySupportUser' && item.status != 'ClosedByEndUser'" @click="ticketClose(item.id)" color="warning">بستن</v-btn>
          </v-row>
        </template>
        <template v-slot:item.priority="{ item }">
          {{item.priority == 'Normal' ? 'متوسط' : (item.priority == 'Low') ? 'پایین' : 'بالا'}}
        </template>
        <template v-slot:item.status="{ item }">
          {{item.status == 'WaitingForSupportUserReply' ? 'در انتظار پاسخ ادمین': (item.status == 'AnsweredBySupportUser' ? 'پاسخ داده شده توسط ادمین':(item.status == 'ClosedByEndUser' ? 'بسته شده توسط کاربر' :(item.status == 'ClosedBySupportUser' ? 'بسته شده توسط پشتیبان':'بسته شده توسط سیستم')))}}
        </template>
        <template v-slot:item.createdAtUtc="{ item }">
          {{getPersianDate(item.createdAtUtc)}}
        </template>
      </v-data-table>
    </v-card>
    <modal modal_id="ticketModal" close_option="true" modal_style="max-width:800px" style="direction:rtl">
      <template slot="body">
        <center>
          <div style="padding: 4%">
            <p style="font-size: 15px">پیام ها</p>
          </div>
          <div class="row">
            <label for="topic" class="col-2">موضوع:</label>
            <input type="text" class="form-control col-10" id="topic" name="title" value="x">
          </div>
          <input type="hidden" name="message_id" value="x">
          <div class="row" style="margin-top: 2%">
            <div class="col-6 row" style="padding: 0px">
              <label for="sentTime" class="col-4">زمان ارسال:</label>
              <input type="text" disabled class="form-control col-8" id="sentTime" name="date" style="direction:ltr" value="x">
            </div>
            <div class="col-6 row" style="padding: 0px">
              <label for="answerTime" class="col-4">زمان پاسخ:</label>
              <input type="text" disabled class="form-control col-8" id="answerTime" style="direction:ltr" value="x">
            </div>
          </div>

          <div class="row" style="margin-top: 2%">
            <label for="message" class="col-2">پیام:</label>

            <textarea class="form-control col-10" id="message" name="message_text" rows="3">x</textarea>

          </div>
          <div class="row" style="margin-top: 2%">
            <label class="col-2">پاسخ پشتیبان:</label>

            <!--                          <textarea class="form-control col-10" id="supportAnswer" rows="3" @if($message->answer_text) disabled  @else v-model="messageAnswerContent"  @endif>{{$message->answer_text}}</textarea>-->

          </div>
          <button class="greenButton" style="margin-top: 10%" type="button">پاسخ</button>
        </center>
      </template>
    </modal>
  </div>
</template>


<script>
import axiosApi from "@/axios";
import Vue from "vue";
import getPersianDateTime from "@/plugins/getPersianDate";

export default {
  name: "UserMessages",
  data(){
    return {
      tickets: [],
      search: '',
      headers: [
        {
          text: 'تیکت کد ',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {text: 'موضوع', value: 'title'},
        {text: 'اولویت', value: 'priority'},
        {text: 'زمان ارسال', value: 'createdAtUtc'},
        {text: 'وضعیت', value: 'status'},
        {text: 'عملیات', value: 'operations'},
      ],

    }
  },
  methods:{
    getTickets()
    {
      axiosApi().post('/api/Manage/v1/Ticket/GetList',{
        username : this.$root.choosedUser
      }).then(({data})=>{
        this.tickets = data.data.reverse()
      })
    },
    getPersianDate(date)
    {
      return getPersianDateTime(date);
    },
    ticketClose(id)
    {
      axiosApi().post('/api/Manage/v1/Ticket/CloseTicket',{
        id : id
      }).then(({data})=>{
        if (data.isSuccess)
          this.getTickets()
        {
          Vue.$toast.open({
            message: 'تیکت با موفقیت بسته شد',
            type: 'success',
            position:'top-right'
          })
        }
      })
    }
  },
  mounted() {
    this.getTickets()
  }
}
</script>

<style scoped>

</style>
